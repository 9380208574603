"use client";

import Script from "next/script";

export function Analytics() {
  return (
    <>
      <Script>{`window.lemonSqueezyAffiliateConfig = { store: "fediraly" };`}</Script>
      <Script src="https://lmsqueezy.com/affiliate.js" defer></Script>
      <Script
        async
        defer
        src="https://www.googletagmanager.com/gtag/js?id=G-GPLJG5NSSY"
      ></Script>
      <Script id="gtag">
        {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'G-GPLJG5NSSY');
`}
      </Script>
    </>
  );
}
