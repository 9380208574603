// hooks/useIntercom.ts
"use client";

import { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';

const useIntercom = (appId: string) => {
  useEffect(() => {
    Intercom({
      app_id: appId,
    });

    // Optionally, add cleanup logic here if needed
    return () => {
      // Cleanup logic (if any) goes here
    };
  }, [appId]);
};

export default useIntercom;
