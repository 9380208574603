// components/IntercomInitializer.tsx
"use client";

import useIntercom from '../components/useIntercom';

const IntercomInitializer = () => {
  useIntercom('yi2xsde2'); // Initialize Intercom with your app ID

  return null; // This component does not render anything
};

export default IntercomInitializer;
